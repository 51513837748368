import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomButton from "../common/CustomButton";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import SkeletonLoader from "../common/SkeletonLoader";
import HistoryChart from "../profile/HubHistoryChart";
import { setSessionId } from "../../store/slices/globalSlice";

const Hub = ({
  userSessionSummariesResponse,
  sessionPerformanceAnalysisResponse,
  userFirstName,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    data: userSessionSummariesData,
    isLoading: userSessionSummariesIsLoading,
    isError: userSessionSummariesIsError,
  } = userSessionSummariesResponse;

  const {
    data: sessionPerformanceAnalysisData,
    isLoading: sessionPerformanceAnalysisIsLoading,
    isError: sessionPerformanceAnalysisIsError,
    error: sessionPerformanceAnalysisError,
  } = sessionPerformanceAnalysisResponse;

  const userSessionSummaries =
    userSessionSummariesData?.data?.recent_session_summaries || [];

  const sessionPerformanceAnalysis =
    sessionPerformanceAnalysisData?.data || null;

  const handleRowClick = (sessionId) => {
    dispatch(setSessionId(sessionId));
    navigate(`../sessions/${sessionId}/performance_analysis/`);
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          margin: "0 auto",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: isMobile ? "column-reverse" : "row",
          }}
        >
          <Grid item xs={12} sx={{ marginBottom: "1em" }}>
            <Typography variant="h2">Hi {userFirstName || "there"}!</Typography>
          </Grid>
          <Grid container sx={{ paddingLeft: "16px" }}>
            <Grid item xs={12}>
              <Typography
                variant={isMobile ? "h5" : "h4"}
                sx={{ marginBottom: "0.5em" }}
              >
                Performance History
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: isMobile ? "14px" : "16px" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {sessionPerformanceAnalysisIsLoading && <SkeletonLoader />}
                {sessionPerformanceAnalysisIsError && (
                  <Typography variant="body1">
                    {sessionPerformanceAnalysisError.message}
                  </Typography>
                )}
                {sessionPerformanceAnalysis && (
                  <HistoryChart
                    sessionPerformanceAnalysisResponse={
                      sessionPerformanceAnalysisResponse
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("../../app/interviewer/session/start");
              }}
            >
              Start New Session
            </CustomButton>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant={isMobile ? "h5" : "h4"}
              sx={{ marginTop: "1em", marginBottom: "0.5em" }}
            >
              Recent Sessions
            </Typography>
            <TableContainer component={Paper} elevation={0}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: blue[900],
                    }}
                  >
                    <TableCell
                      sx={{ color: "white", fontWeight: "400" }}
                      align="left"
                    >
                      DATE
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "400" }}
                      align="left"
                    >
                      DURATION
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "400" }}
                      align="left"
                    >
                      INTERVIEWER
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "400" }}
                      align="left"
                    >
                      JOB TITLE
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "400" }}
                      align="left"
                    >
                      GLOBAL SCORE
                    </TableCell>
                    <TableCell
                      sx={{ color: "white", fontWeight: "400" }}
                      align="left"
                    >
                      TREND
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userSessionSummaries.slice(0, 5).map((session, index) => (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => handleRowClick(session.session_id)}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        {new Date(session.start_date_time).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="left">{`${session.duration} min`}</TableCell>
                      <TableCell align="left">{session.interviewer}</TableCell>
                      <TableCell align="left">{session.job_title}</TableCell>
                      <TableCell align="left">{session.global_score}</TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: session.trend >= 0 ? "green" : "red",
                        }}
                      >
                        {session.trend >= 0
                          ? `+${session.trend} pts`
                          : `${session.trend} pts`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Resources
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <a
                    href="https://example1.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://example1.com
                  </a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Short description for link 1
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <a
                    href="https://example2.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://example2.com
                  </a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Short description for link 2
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <a
                    href="https://example3.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://example3.com
                  </a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Short description for link 3
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <a
                    href="https://example4.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://example4.com
                  </a>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Short description for link 4
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Hub;
