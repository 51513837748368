import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobDescription: '',
  timeLimit: 1,
  extractedCVText: '',
  fileName: '',
};

const submissionSlice = createSlice({
  name: 'submission',
  initialState,
  reducers: {
    setJobDescription: (state, action) => {
      state.jobDescription = action.payload;
    },
    setTimeLimit: (state, action) => {
      state.timeLimit = action.payload;
    },
    setExtractedCVText: (state, action) => {
      state.extractedCVText = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    }
  },
});

export const { setJobDescription, setTimeLimit, setExtractedCVText, setFileName } = submissionSlice.actions;

export default submissionSlice.reducer;
