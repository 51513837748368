import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import "typeface-montserrat";
import "@fontsource/roboto/400.css";
import "@fontsource/poppins";
import Conversation from "./Conversation";
import CustomCard from "../common/CustomCard";

const ConversationCard = ({ data, error, isLoading }) => {
  const analysis = data
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={12}>
      <CustomCard elevation={4}>
        <Typography
          variant="h5"
          color="primary"
          fontWeight={600}
          sx={{ paddingBottom: 2 }}
        >
          {t("Detailed Step by Step Analysis")}
        </Typography>
        <Conversation
          conversations={analysis?.performance_analysis?.conversation_analysis}
          isLoading={isLoading}
        />
      </CustomCard>
    </Grid>
  );
};

export default ConversationCard;