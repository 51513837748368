import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { setSessionId } from "../../store/slices/globalSlice";

const SessionHistory = ({ userSessionSummariesResponse }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRowClick = (sessionId) => {
    dispatch(setSessionId(sessionId));
    navigate(`/account/sessions/${sessionId}/performance_analysis/`);
  };

  const {
    data: userSessionSummariesData,
    isLoading: userSessionSummariesIsLoading,
    isError: userSessionSummariesIsError,
  } = userSessionSummariesResponse;

  const userSessionSummaries =
    userSessionSummariesData?.data?.recent_session_summaries || [];

  const handleSignOut = () => {
    navigate("/");
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          margin: "0 auto",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{ marginTop: "1em", marginBottom: "0.5em" }}
          >
            Session History
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: blue[900],
                  }}
                >
                  <TableCell
                    sx={{ color: "white", fontWeight: "400" }}
                    align="left"
                  >
                    DATE
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "400" }}
                    align="left"
                  >
                    DURATION
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "400" }}
                    align="left"
                  >
                    INTERVIEWER
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "400" }}
                    align="left"
                  >
                    JOB TITLE
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "400" }}
                    align="left"
                  >
                    GLOBAL SCORE
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: "400" }}
                    align="left"
                  >
                    TREND
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userSessionSummaries.map((session, index) => (
                  <TableRow
                    hover
                    key={index}
                    onClick={() => handleRowClick(session.session_id)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>
                      {new Date(session.start_date_time).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="left">{`${session.duration} min`}</TableCell>
                    <TableCell align="left">{session.interviewer}</TableCell>
                    <TableCell align="left">{session.job_title}</TableCell>
                    <TableCell align="left">{session.global_score}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: session.trend >= 0 ? "green" : "red",
                      }}
                    >
                      {session.trend >= 0
                        ? `+${session.trend} pts`
                        : `${session.trend} pts`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </>
  );
};

export default SessionHistory;
