// Scores Item component

import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, LinearProgress } from "@mui/material";
import "typeface-montserrat";
import "@fontsource/roboto/400.css";
import "@fontsource/poppins";

const ScoresItem = ({ title, titleSize, list = [] }) => {
  const { t } = useTranslation();

  const formatScore = (score) => {
    if (typeof score !== "number") return score;
    const twoDecimals = score.toFixed(2);

    if (twoDecimals.endsWith("00")) {
      return score.toFixed(0);
    } else if (twoDecimals.endsWith("0")) {
      return score.toFixed(1);
    } else {
      return twoDecimals;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        {title !== null && (
          <Typography variant={titleSize} color="primary" fontWeight={600}>
            {title}
          </Typography>
        )}
      </Grid>
      {list.map((subScore, i) => (
        <Grid
          item
          sx={{ minWidth: "187px", flex: "1 1 0%", paddingBottom: 0.5 }}
          key={i}
        >
          <Typography
            variant="body2"
            paddingBottom={0.5}
            color={
              subScore.score < 40
                ? "error"
                : subScore.score < 70
                ? "warning"
                : "success"
            }
          >
            {subScore.name} ({formatScore(subScore.score)})
          </Typography>
          <LinearProgress
            variant="determinate"
            value={subScore.score}
            color={
              subScore.score < 40
                ? "error"
                : subScore.score < 70
                ? "warning"
                : "success"
            }
            sx={{ height: "12px", borderRadius: "7px" }}
          />
        </Grid>
      ))}
    </>
  );
};

export default ScoresItem;
