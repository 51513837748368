import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

const CountdownTimer = ({ timeLimit = 0, startTimer }) => {
  // Calculate initial minutes and seconds from timeLimit
  const initialMinutes = Math.floor((timeLimit * 60) / 60);
  const initialSeconds = (timeLimit * 60) % 60;

  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isTimeUp, setIsTimeUp] = useState(false);

  useEffect(() => {
    let interval;
    if (startTimer) {
      interval = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
            setIsTimeUp(true);
          } else {
            setMinutes((m) => m - 1);
            setSeconds(59);
          }
        } else {
          setSeconds((s) => s - 1);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [startTimer, minutes, seconds]);

  return (
    <Box
      sx={{
        color: isTimeUp ? "red" : "inherit",
        animation: isTimeUp ? "flash 1s infinite" : "none",
        fontWeight: "bold",
      }}
    >
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </Box>
  );
};

export default CountdownTimer;
