// SkeletonLoader.js
import Skeleton from '@mui/material/Skeleton';

const SkeletonLoader = () => (
    <>
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
    </>
    );

export default SkeletonLoader;