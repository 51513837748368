import { configureStore } from '@reduxjs/toolkit';
import globalReducer from './slices/globalSlice';
import submissionReducer from './slices/submissionSlice';
import interviewReducer from './slices/interviewSlice';
import performanceAnalysisReducer from './slices/performanceAnalysisSlice';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    submission: submissionReducer,
    interview: interviewReducer,
    performanceAnalysis: performanceAnalysisReducer,
  },
});

export default store;