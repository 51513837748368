// User Profile Page component

import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { styled, useTheme } from "@mui/system";
import { useFetchUserInfo } from "../../hooks/useUserInformationProcess";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Divider,
  ListSubheader,
} from "@mui/material";
import {
  Description,
  ExitToApp,
  History,
  Home,
  Person,
  PlayCircleOutline,
  Settings,
  SettingsApplications,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Hub from "./Hub";
import SessionHistory from "./SessionHistory";
import PerformanceAnalysisPage from "../performanceAnalysis/PerformanceAnalysisPage";
import {
  useGetUserSessionSummaries,
  useGetSessionPerformanceAnalysis,
} from "../../hooks/usePerformanceAnalysisProcess";

// Styling for the drawer header
const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const UserProfilePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  // Managing state for the drawer's open/close status
  const [drawerOpen, setDrawerOpen] = useState(!isMobile);

  // Fetching user session summaries and session performance analysis
  const userSessionSummariesResponse = useGetUserSessionSummaries(
    user?.sub,
    null,
    sessionStorage.getItem("accessToken")
  );
  const sessionPerformanceAnalysisResponse = useGetSessionPerformanceAnalysis(
    null,
    sessionStorage.getItem("accessToken")
  );

  useEffect(() => {
    const fetchToken = async () => {
      const accessToken = await getAccessTokenSilently();
      sessionStorage.setItem("accessToken", accessToken);
      console.log("Access Token:", accessToken);
      console.log("Stored Token:", sessionStorage.getItem("accessToken"));
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  const {
    data: userInfo,
    isLoading: userInfoLoading,
    isError: userInfoError,
  } = useFetchUserInfo(sessionStorage.getItem("accessToken"));

  return (
    <Box sx={{ display: "flex" }}>
      {/* Sidebar Drawer */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: 240,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader />
        <List>
          {/* Conditionally render the 'Back to Hub' button */}
          {location.pathname !== "/account/hub" && (
            <ListItemButton
              onClick={() => navigate("hub")}
              sx={{ backgroundColor: "grey.300" }}
            >
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Back to Hub" />
            </ListItemButton>
          )}
          <Divider />

          {/* Session-related navigational links */}
          <ListSubheader>Session</ListSubheader>
          {[
            {
              text: "Start New Session",
              icon: <PlayCircleOutline />,
              route: "../app/interviewer/session/start",
            },
            {
              text: "Session History",
              icon: <History />,
              route: "sessions",
            },
            {
              text: "Session Preferences",
              icon: <Settings />,
              route: "session_preferences",
            },
          ].map((item) => (
            <ListItemButton
              key={item.text}
              onClick={() => navigate(item.route)}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
          <Divider />

          {/* Profile-related navigational links */}
          <ListSubheader>Profile</ListSubheader>
          {[
            { text: "Personal Info", icon: <Person /> },
            { text: "Resume Builder", icon: <Description /> },
          ].map((item) => (
            <ListItemButton key={item.text}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          ))}
          <Divider />

          {/* Settings-related navigational links */}
          <ListSubheader>Settings</ListSubheader>
          {[{ text: "Account Settings", icon: <SettingsApplications /> }].map(
            (item) => (
              <ListItemButton key={item.text}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            )
          )}
          <Divider />

          {/* Sign out button */}
          <ListItemButton
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItemButton>
        </List>
      </Drawer>

      {/* Main Content Area */}
      <DrawerHeader />
      <Box component="main" sx={{ flexGrow: 1, paddingTop: "1.25em" }}>
        <Routes>
          {/* Defining the application routes and passing required props to child components */}
          <Route
            path="sessions"
            element={
              <SessionHistory
                userSessionSummariesResponse={userSessionSummariesResponse}
              />
            }
          />
          <Route
            path="hub"
            element={
              <Hub
                userFirstName={userInfo?.data?.first_name}
                userSessionSummariesResponse={userSessionSummariesResponse}
                sessionPerformanceAnalysisResponse={
                  sessionPerformanceAnalysisResponse
                }
              />
            }
          />
          <Route
            path="sessions/:sessionId/performance_analysis"
            element={
              <PerformanceAnalysisPage
                sessionPerformanceAnalysisResponse={
                  sessionPerformanceAnalysisResponse
                }
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default UserProfilePage;
