  import React, { useState } from "react";
  import Accordion from "@mui/material/Accordion";
  import AccordionSummary from "@mui/material/AccordionSummary";
  import AccordionDetails from "@mui/material/AccordionDetails";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import { useTranslation } from "react-i18next";
  import { Card, Grid, Typography, Box } from "@mui/material";
  import TitledTextItem from "../common/TitledTextItem";
  import ScoresItem from "./ScoresItem";
  import { green, grey } from "@mui/material/colors";
  import { styled } from "@mui/system";
  import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
  import SkeletonLoader from "../common/SkeletonLoader";

  const RoundedAccordion = styled(Accordion)(({ theme }) => ({
    "&.MuiAccordion-root": {
      boxShadow: "none",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      border: "1px solid rgba(0, 0, 0, .15)",
      borderRadius: "0.4em",
      //boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
      "& .MuiAccordionSummary-root": {
        borderRadius: "0.4em 0.4em 0 0",
      },
      marginBottom: "2em",

      "& .MuiAccordionDetails-root": {
        borderRadius: "0 0 0.4em 0.4em",
      },
    },
  }));

  const RoundedAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "&.MuiAccordionSummary-root.Mui-expanded": {
      borderRadius: "0.4em 0.4em 0 0",
    },
  }));

  const RoundedAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    borderRadius: "0 0 0.4em 0.4em",
  }));

  const InnerRoundedAccordion = styled(Accordion)(({ theme }) => ({
    "&.MuiAccordion-root": {
      border: "1px solid rgba(0, 0, 0, .15)",
      boxShadow: "none",
      borderRadius: "0.4em 0.4em 0.4em 0.4em",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      borderRadius: "0.4em 0.4em 0.4em 0.4em",
      boxShadow: "none",
      "& .MuiAccordionSummary-root": {
        borderRadius: "0.4em 0.4em 0 0",
      },
      "& .MuiAccordionDetails-root": {
        borderRadius: "0 0 0.4em 0.4em",
      },
    },
  }));

  const InnerRoundedAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "&.MuiAccordionSummary-root.Mui-expanded": {
      borderRadius: "0.4em 0.4em 0 0",
    },
  }));

  const InnerRoundedAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    borderRadius: "0 0 0.4em 0.4em",
  }));

  const Conversation = ({ conversations = [], isLoading }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(null); // track which accordion is expanded

    const handleBarClick = (data, index) => {
      setExpanded(expanded === `panel${index}` ? null : `panel${index}`);
    };

    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const hasNoNullValues = (conversation) => {
      // Check all properties of the conversation object for null values
      // Return false if any property is null, otherwise return true
      return Object.values(conversation).every(value => value !== null);
    };

    const chartData = conversations
      .map((conversation, i) => {
        if (conversation?.performance_analysis?.sub_scores) {
          let avgScore =
            conversation.performance_analysis.sub_scores.reduce(
              (a, b) => a + b.score,
              0
            ) / conversation.performance_analysis.sub_scores.length;
          return {
            name: `Q${i + 1}`,
            avgScore: avgScore,
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    const getPath = (x, y, width, height) => {
      return `
        M${x},${y + height}
        L${x},${y + 5} A5,5 0 0 1 ${x + 5},${y}
        L${x + width - 5},${y} A5,5 0 0 1 ${x + width},${y + 5}
        L${x + width},${y + height}Z
      `;
    };

    const RoundedTopBar = (props) => {
      const { fill, x, y, width, height } = props;
      return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
    };
    if (!isLoading) {

      return (
        <>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              width={500}
              height={300}
              data={chartData}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="name" stroke="#8884d8" />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return <div className="custom-tooltip"></div>;
                  }
                  return null;
                }}
              />
              <Bar
                dataKey="avgScore"
                fill="url(#colorUv)"
                label={{ position: "top" }}
                shape={RoundedTopBar}
                onClick={handleBarClick}
              />
            </BarChart>
          </ResponsiveContainer>
          {conversations
          .filter(hasNoNullValues).map((conversation, i) => (
            <RoundedAccordion
              key={i}
              sx={{ width: "100%" }}
              expanded={expanded === `panel${i}`}
              onChange={(event, isExpanded) => {
                setExpanded(isExpanded ? `panel${i}` : false);
              }}
            >
              <RoundedAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i}-content`}
                id={`panel${i}-header`}
              >
                <Typography variant="h6" color="primary" fontWeight={600}>
                  <Box
                    component="span"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {`${capitalizeFirstLetter(
                      conversation.answer_category
                    )} Question`}
                  </Box>
                </Typography>
              </RoundedAccordionSummary>
              <RoundedAccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h7" color="primary" fontWeight={600}>
                      {t("Question")}
                    </Typography>
                    <Card
                      sx={{
                        boxShadow: "none",
                        marginTop: "1em",
                        padding: 2,
                        borderRadius: "2em 2em 2em 0",
                        backgroundColor: grey[300],
                      }}
                    >
                      <Typography variant="body1" color="textSecondary">
                        {isLoading ? (
                          <SkeletonLoader />
                        ) : (
                          conversation.question_text
                        )}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h7" color="primary" fontWeight={600}>
                      {t("Answer")}
                    </Typography>
                    <Card
                      sx={{
                        boxShadow: "none",
                        marginTop: "1em",
                        padding: 2,
                        borderRadius: "2em 2em 0 2em",
                        backgroundColor: green[100],
                      }}
                    >
                      <Typography variant="body1" color="textSecondary">
                        {isLoading ? (
                          <SkeletonLoader />
                        ) : (
                          conversation.answer_text
                        )}
                      </Typography>
                    </Card>
                  </Grid>
                  {/* ---------------- Step Performance Analysis ---------------- */}
                  <Grid item xs={12}>
                    <InnerRoundedAccordion
                      sx={{
                        backgroundColor: grey[100],
                      }}
                    >
                      <InnerRoundedAccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h7" color="primary" fontWeight={600}>
                          {t("Step Performance Analysis")}
                        </Typography>
                      </InnerRoundedAccordionSummary>
                      <InnerRoundedAccordionDetails>
                        {isLoading ? (
                          <SkeletonLoader />
                        ) : conversation.performance_analysis ? (
                          <Grid container spacing={3}>
                            {/* ---------------- Summary ---------------- */}
                            <Grid item xs={12}>
                              <TitledTextItem
                                titleSize={"h7"}
                                text={conversation?.performance_analysis?.summary}
                              />
                            </Grid>
                            {/* ---------------- Detailed Scores ---------------- */}
                            <ScoresItem
                              title="Detailed Scores"
                              titleSize="h7"
                              list={
                                conversation?.performance_analysis?.sub_scores
                              }
                            />
                          </Grid>
                        ) : (
                          <Typography variant="body1" color="textSecondary">
                            No performance analysis was generated for this
                            interaction.
                          </Typography>
                        )}
                      </InnerRoundedAccordionDetails>
                    </InnerRoundedAccordion>
                  </Grid>
                </Grid>
              </RoundedAccordionDetails>
            </RoundedAccordion>
          ))}
        </>
      );
    }
  };

  export default Conversation;
