// Overall Performance Card Component

import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import HistoryChart from "./PAHistoryChart";
import "typeface-montserrat";
import "@fontsource/roboto/400.css";
import "@fontsource/poppins";
import ScoresItem from "./ScoresItem";
import CustomCard from "../common/CustomCard";
import SkeletonLoader from "../common/SkeletonLoader";

const OverallPerformanceCard = ({ data, error, isLoading }) => {
  const { t } = useTranslation();
  const analysis = data;
  const lastScore =
    analysis?.global_score_history?.[
      analysis?.global_score_history?.length - 1
    ];
  const secondLastScore =
    analysis?.global_score_history?.[
      analysis?.global_score_history?.length - 2
    ];
  const hasEnoughHistory = analysis?.global_score_history?.length >= 2;
  const scoreDifference = hasEnoughHistory
    ? (lastScore - secondLastScore || 0).toFixed(2)
    : null;

  return (
    <Grid
      item
      sx={{
        flex: "1 1 0%",
      }}
    >
      <CustomCard elevation={4}>
        <Grid container direction="row">
          <Grid
            item
            sx={{
              minWidth: "190px",
              flex: "1 1 0%",
            }}
          >
            <Typography variant="h2" gutterBottom>
              {isLoading ? (
                <SkeletonLoader />
              ) : (
                `${analysis?.performance_analysis?.global_score.toFixed(2)}`
              )}
            </Typography>
            {hasEnoughHistory && (
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{
                  color: isLoading
                    ? "inherit"
                    : lastScore > secondLastScore
                    ? "green"
                    : "red",
                }}
              >
                {isLoading
                  ? ""
                  : `${
                      lastScore > secondLastScore ? "+" : ""
                    }${scoreDifference} pts from last attempt`}
              </Typography>
            )}
          </Grid>

          {/*--------------- Global Score History Chart ---------------*/}
          <HistoryChart data={data} error={error} isLoading={isLoading} />
        </Grid>

        {/* ---------------- Detailed Scores ---------------- */}
        <Accordion
          sx={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" color="primary" fontWeight={600}>
              {t("Detailed Scores")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <Grid container spacing={2}>
                <ScoresItem
                  titleSize="h7"
                  list={analysis?.performance_analysis?.sub_scores}
                />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </CustomCard>
    </Grid>
  );
};

export default OverallPerformanceCard;
