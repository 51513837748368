// ResponseForm component

import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setResponse } from "../../store/slices/globalSlice";
import {
  setIsDictating,
  setInterim,
  setConversation,
  setIsClosingStatement,
} from "../../store/slices/interviewSlice";
import { TextField, Stack, Fab } from "@mui/material";
import { useTheme, keyframes } from "@mui/system";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useNavigate } from "react-router-dom";
import CustomButton from "../common/CustomButton";
import { useSubmitUserResponse } from "../../hooks/useInterviewProcess";
import { use } from "i18next";

const pulsate = keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  `;

const ResponseForm = ({
  interviewerResponseMutation,
  onFinish,
  isClosingStatement,
}) => {
  const dispatch = useDispatch();
  const response = useSelector((state) => state.global.response);
  const interim = useSelector((state) => state.interview.interim);
  const isDictating = useSelector((state) => state.interview.isDictating);
  const currentResponse = useSelector((state) => state.global.response);
  const jobDescription = useSelector(
    (state) => state.submission.jobDescription
  );
  const timeLimit = useSelector((state) => state.submission.timeLimit);
  const extractedCVText = useSelector(
    (state) => state.submission.extractedCVText
  );
  const selectedLanguage = useSelector(
    (state) => state.global.selectedLanguage
  );
  const conversation = useSelector((state) => state.interview.conversation);
  const sessionId = useSelector((state) => state.global.sessionId);
  const navigate = useNavigate();

  const submitUserResponseMutation = useSubmitUserResponse();

  const { t } = useTranslation();

  const recognition = useRef(null);
  const oldConversation = useRef(conversation);

  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      setConversation({
        role: "user",
        content: response,
      })
    );
  };

  useEffect(() => {
    if (oldConversation.current !== conversation) {
      submitUserResponse();
    }
  }, [conversation]);

  const submitUserResponse = () => {
    submitUserResponseMutation.mutate(
      {
        questionId: interviewerResponseMutation.data.data.question_id,
        answer: response,
        answerCategory: interviewerResponseMutation.data.data.question_type,
        sessionId: sessionId,
        token: sessionStorage.getItem("accessToken"),
      },
      {
        onSuccess: () => {
          generateInterviewerResponse();
          oldConversation.current = conversation;
          dispatch(setResponse(""));
        },
        onError: (error) => {
          console.error("Error generating interviewer response:", error);
        },
      }
    );
  };

  const generateInterviewerResponse = () => {
    interviewerResponseMutation.mutate(
      {
        jobDescription: jobDescription,
        extractedCVText: extractedCVText,
        timeLimit: timeLimit,
        sessionId: sessionId,
        selectedLanguage: selectedLanguage,
        conversation: conversation,
        token: sessionStorage.getItem("accessToken"),
      },
      {
        onSuccess: (data) => {
          dispatch(
            setConversation({
              role: "assistant",
              content: data.data.question_text,
            })
          );
          dispatch(setIsClosingStatement(data.data.is_closing_statement));
        },
        onError: (error) => {
          console.error("Error generating interviewer response:", error);
        },
      }
    );
  };

  const handleFinishClick = () => {
    onFinish();
    navigate(`/account/sessions/${sessionId}/performance_analysis`);
  };

  const startDictation = () => {
    if (!window.webkitSpeechRecognition) {
      alert("Sorry, your browser does not support the dictation feature.");
      return;
    }

    if (!recognition.current) {
      recognition.current = new window.webkitSpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = "en-US";

      const grammar =
        "#JSGF V1.0; grammar punctuation; public <punctuation> = . | , | ? | ! | ; | : ;";
      const speechRecognitionList = new window.webkitSpeechGrammarList();
      speechRecognitionList.addFromString(grammar, 1);
      recognition.current.grammars = speechRecognitionList;

      recognition.current.onresult = function (event) {
        let interimTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            dispatch(
              setResponse(currentResponse + event.results[i][0].transcript)
            );
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        dispatch(setInterim(interimTranscript));
      };

      recognition.current.onerror = function (event) {
        recognition.current.stop();
      };
    }

    if (isDictating) {
      recognition.current.stop();
    } else {
      recognition.current.start();
    }
    dispatch(setIsDictating(!isDictating));
  };

  return (
    <form onSubmit={handleSubmit} className="response-form">
      {!isClosingStatement && (
        <>
          <TextField
            label={t("responseFieldPlaceholder")}
            multiline
            rows={4}
            variant="outlined"
            value={response + interim}
            onChange={(e) => dispatch(setResponse(e.target.value))}
            fullWidth
            required
            sx={{ marginBottom: 2 }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Fab
              color="primary"
              aria-label="record"
              onClick={startDictation}
              sx={{
                backgroundColor: isDictating
                  ? theme.palette.success.main
                  : theme.palette.error.main,
                "&:hover": {
                  backgroundColor: isDictating
                    ? theme.palette.success.dark
                    : theme.palette.error.dark,
                },
                transform: "scale(0.75)",
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  animation: isDictating ? `${pulsate} 1.5s infinite` : "none",
                }}
              />
            </Fab>
            {!isClosingStatement && (
              <CustomButton
                type="submit"
                variant="contained"
                sx={{ width: "85%" }}
              >
                {t("next")}
              </CustomButton>
            )}
          </Stack>
        </>
      )}
      {isClosingStatement && (
        <CustomButton
          type="button"
          variant="contained"
          onClick={handleFinishClick}
          sx={{ width: "100%" }}
        >
          Finish
        </CustomButton>
      )}
    </form>
  );
};

export default ResponseForm;
