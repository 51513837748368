// use interview process custom hook

import { useMutation } from "react-query";
import {
  createSession,
  submitUserResponse,
  generateInterviewerResponse,
  handleRetry,
  closeSession
} from "../services/interviewService";

const useCreateSession = () => {
  return useMutation(createSession);
};

const useCloseSession = () => {
  return useMutation(closeSession);
}

const useSubmitUserResponse = () => {
  return useMutation(submitUserResponse);
};

const useGenerateInterviewerResponse = () => {
  return useMutation(generateInterviewerResponse);
};

const useHandleRetry = ({
  jobDescription,
  conversation,
  isClosingStatement,
  sessionId,
  selectedLanguage,
  token,
}) => {
  return useMutation(() =>
    handleRetry({
      jobDescription,
      conversation,
      isClosingStatement,
      sessionId,
      selectedLanguage,
      token
    })
  );
};

export {
  useSubmitUserResponse,
  useGenerateInterviewerResponse,
  useHandleRetry,
  useCreateSession,
  useCloseSession
};
