// src/styles/theme.js
import { createTheme } from "@mui/material/styles";
import { green, yellow, red } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4a4a4a",
    },
    secondary: {
      main: "#00796b",
    },
    success: {
      main: green[500],
    },
    warning: {
      main: yellow[700],
    },
    error: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
  },
});

export default theme;
