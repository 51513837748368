// Intevriew service to handle all requests to the backend

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const handleResponse = async (response) => {
  if (
    response.status === 201 ||
    response.status === 200 ||
    response.status === 202
  ) {
    const data = await response.json();
    return { status: response.status, data };
  } else if (response.status === 204) {
    return { status: 204 };
  } else {
    throw new Error(
      `HTTP error! status: ${response.status} ${response.statusText} ${response.url}`
    );
  }
};

export const createSession = async ({
  jobDescription,
  extractedCVText,
  timeLimit,
  sessionId,
  selectedLanguage,
  token,
}) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/v1/interviews/start_session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          job_description: jobDescription,
          cv_text: extractedCVText,
          time_limit: timeLimit,
          session_id: sessionId,
          language: selectedLanguage,
        }),
      }
    );
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};

export const closeSession = async ({sessionId, token}) => {
  try {
    const baseUrl = `${API_BASE_URL}/api/v1/interviews`;
    const url = `${baseUrl}/${sessionId}/close_session`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};

export const submitUserResponse = async ({
  questionId,
  answer,
  answerCategory,
  sessionId,
  token
}) => {
  try {
    const baseUrl = `${API_BASE_URL}/api/v1/interviews`;
    const url = `${baseUrl}/${sessionId}/user_responses/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        question_id: questionId,
        answer_text: answer,
        answer_category: answerCategory,
        session_id: sessionId,
      }),
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};

export const generateInterviewerResponse = async ({
  jobDescription,
  extractedCVText,
  timeLimit,
  sessionId,
  selectedLanguage,
  conversation,
  token
}) => {
  try {
    const baseUrl = `${API_BASE_URL}/api/v1/interviews`;
    const url = `${baseUrl}/${sessionId}/interviewer_responses/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        job_description: jobDescription,
        cv_text: extractedCVText,
        time_limit: timeLimit,
        session_id: sessionId,
        language: selectedLanguage,
        conversation: conversation,
      }),
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};

export const handleRetry = async ({
  jobDescription,
  conversation,
  isClosingStatement,
  sessionId,
  selectedLanguage,
  token
}) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/interviews/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        job_description: jobDescription,
        conversation: conversation,
        is_closing_statement: isClosingStatement,
        session_id: sessionId,
        language: selectedLanguage,
      }),
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};
