// TextCard component

import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import "typeface-montserrat";
import "@fontsource/roboto/400.css";
import "@fontsource/poppins";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

const TitledTextItem = ({ title, titleSize, text }) => {
  const isFetchingSessionPerformanceAnalysis = useSelector(
    (state) => state.performanceAnalysis.isFetchingSessionPerformanceAnalysis
  );
  const isLoading = useSelector((state) => state.global.isLoading);
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {title ? (
        <Typography
          variant={titleSize}
          color="primary"
          fontWeight={600}
          sx={{
            paddingBottom: 2,
            fontSize: {
              xs: "2.5rem", // For mobile views
              sm: "3rem", // For tablet views
              md: "3.5rem", // Default size for larger screens
            },
          }}
        >
          {parse(t(title))}
        </Typography>
        ) : null} 
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "justify" }}
        >
          {text ? parse(text) : null}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitledTextItem;
