// Text to speech service

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const handleResponse = async (response) => {
  if (
    response.status === 201 ||
    response.status === 200 ||
    response.status === 202
  ) {
    const data = await response.json();
    return { status: response.status, data };
  } else if (response.status === 204) {
    return { status: 204 };
  } else {
    throw new Error(
      `HTTP error! status: ${response.status} ${response.statusText} ${response.url}`
    );
  }
};

export const generateAudioFromText = async ({ text, session_id, token }) => {
  try {
    const baseUrl = `${API_BASE_URL}/api/v1/text_to_speech`;
    const url = `${baseUrl}/convert_text/`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        text: text,
        session_id: session_id
      }),
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};