// performanceAnalysisService.jsx
// Performance Analysis service to handle all requests to the backend

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const handleResponse = async (response) => {
  if (response.status === 200 || response.status === 202) {
    if (response.headers.get("content-length") !== "0") {
      const data = await response.json();
      return { status: response.status, data };
    } else {
      return { status: response.status };
    }
  } else if (response.status === 204) {
    throw new Error("Performance analysis not yet available");
  } else if (response.status === 409) {
    throw new Error("Performance analysis already exists");
  } else {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
};

export const generatePerformanceAnalysis = async ({sessionId, token}) => {
  console.log("generatePerformanceAnalysis called");
  try {
    const response = await fetch(
        `${API_BASE_URL}/api/v1/performance_analysis/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          session_id: sessionId,
        }),
      }
    );
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};

export const getSessionPerformanceAnalysis = async (sessionId, token) => {
  try {
    const baseUrl = `${API_BASE_URL}/api/v1/sessions`;
    const url = sessionId ? `${baseUrl}/${sessionId}/performance_analysis/` : baseUrl + "/performance_analysis/";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};

export const getUserSessionSummaries = async (user_id, amount, token) => {
  try {
    const baseUrl = `${API_BASE_URL}/api/v1/performance_analysis/${user_id}/session_summaries`;
    const url = amount ? baseUrl + `/?n=${amount}/` : baseUrl + "/";
    const response = await fetch(url,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return handleResponse(response);
  } catch (err) {
    console.error(err.message);
    return { status: 500, error: err.message };
  }
};
