import React from "react";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import Skeleton from "@mui/material/Skeleton";
import { Typography } from "@mui/material";

const HistoryChart = ({ sessionPerformanceAnalysisResponse }) => {
  const SkeletonLoader = () => <Skeleton animation="wave" />;

  const {
    data: sessionPerformanceAnalysisData,
    isLoading: sessionPerformanceAnalysisIsLoading,
    isError: sessionPerformanceAnalysisIsError,
    error: sessionPerformanceAnalysisError,
  } = sessionPerformanceAnalysisResponse;

  const sessionPerformanceAnalysis =
    sessionPerformanceAnalysisData?.data || null;

  const formatChartData = (global_scores) => {
    if (global_scores) {
      return [...global_scores].map((score, index) => ({
        name: `Interview ${index + 1}`,
        score,
      }));
    } else {
      return [];
    }
  };

  const getPath = (x, y, width, height) => {
    return `
      M${x},${y + height}
      L${x},${y + 3} A5,5 0 0 1 ${x + 3},${y}
      L${x + width - 3},${y} A5,5 0 0 1 ${x + width},${y + 3}
      L${x + width},${y + height}Z
    `;
  };

  const RoundedTopBar = (props) => {
    const { fill, x, y, width, height } = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return sessionPerformanceAnalysisIsLoading ? (
    <SkeletonLoader />
  ) : sessionPerformanceAnalysis?.global_score_history ? (
    <ResponsiveContainer height={150}>
      <BarChart
        data={formatChartData(sessionPerformanceAnalysis?.global_score_history)}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis tick={false} dataKey="name" stroke="#8884d8" />
        <Tooltip
          offset={-8}
          allowEscapeViewBox={{ x: true }}
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              return (
                <div className="custom-tooltip">
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    className="label"
                  >
                    {payload[0].value}
                  </Typography>
                </div>
              );
            }
            return null;
          }}
          position={{ y: 10 }}
        />
        <Bar dataKey="score" fill="url(#colorUv)" shape={RoundedTopBar} />
      </BarChart>
    </ResponsiveContainer>
  ) : null;
};

export default HistoryChart;
