// HorizontalAppBar.jsx

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Toolbar,
  ButtonGroup,
  Button,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelectedLanguage } from "../../store/slices/globalSlice";
import Grid from "@mui/material/Grid";
import logo from "../../assets/logos/dialogue.png";
import LanguageIcon from "@mui/icons-material/Language";

const HorizontalAppBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleLanguageChange = (language) => {
    dispatch(setSelectedLanguage(language));
    i18n.changeLanguage(language);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { loginWithRedirect } = useAuth0();

  const buttonLinks = [
    { label: "Features", path: "/" },
    { label: "Try Now", path: "/session/start" },
    { label: "Pricing", path: "/pricing" },
    { label: "About Us", path: "/about-us" },
    { label: "Contact", path: "/contact" },
  ];

  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        backgroundColor: "grey.50",
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
        height: "4.5rem",
        zIndex: 1301,
      })}
    >
      <Toolbar
        sx={(theme) => ({
          padding: "0 1rem",
          color: theme.palette.primary.contrastText,
          minHeight: "20px",
        })}
      >
        <Grid container>
          <Grid item xs={2} alignItems="center" display="flex">
            <img
              src={logo}
              alt="logo"
              style={{ height: "3 rem", width: "3rem", paddingRight: "1rem" }}
            />
            <Typography variant="h3" fontWeight={420} fontFamily={"avenir"} color={"#0B60B0"}>
              InterSim
            </Typography>
          </Grid>

          <Grid
            item
            xs={8}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {location.pathname === "/" && (
              <ButtonGroup
                size="medium"
                variant="text"
                aria-label="navigation links"
              >
                {buttonLinks.map((button, index) => (
                  <Button
                    key={index}
                    component={Link}
                    to={button.path}
                    sx={{ paddingLeft: "25px", paddingRight: "25px" }}
                  >
                    {button.label}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </Grid>

          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button variant="outlined" onClick={handleMenuOpen} sx={{marginRight: "1rem"}}>
              <LanguageIcon />
              <Typography
                variant="body1"
                color="inherit"
                style={{ marginLeft: "8px" }}
              >
                {i18n.language.toUpperCase()}
              </Typography>
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ zIndex: 1302 }}
            >
              <MenuItem
                onClick={() => handleLanguageChange("en")}
                selected={i18n.language === "en"}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => handleLanguageChange("fr")}
                selected={i18n.language === "fr"}
              >
                Français
              </MenuItem>
              {/* Add more MenuItems for other languages here */}
            </Menu>
            {location.pathname === "/" && (
              <Button onClick={() => loginWithRedirect()}>
                Sign In
              </Button>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default HorizontalAppBar;