  // Performance Analysis Page component

  import React, { useEffect } from "react";
  import {
    Grid,
    useMediaQuery,
    ThemeProvider,
    Card,
    CardContent,
    Typography,
    CircularProgress,
  } from "@mui/material";
  import { useSelector } from "react-redux";

  import ConversationCard from "./ConversationCard";
  import MotivationalCard from "../common/MotivationalCard";
  import theme from "../../styles/theme";
  import OverallPerformanceCard from "./OverallPerformanceCard";
  import StrenghtsWeaknessCard from "./StrengthsWeaknessCard";
  import {
    useGeneratePerformanceAnalysis,
    useGetSessionPerformanceAnalysis,
  } from "../../hooks/usePerformanceAnalysisProcess";
  import { useCloseSession } from "../../hooks/useInterviewProcess";
  import { use } from "i18next";

  const PerformanceAnalysisPage = () => {
    const isFinished = useSelector((state) => state.interview.isFinished);
    const sessionId = useSelector((state) => state.global.sessionId);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [token, setToken] = React.useState(sessionStorage.getItem("accessToken"));

    const generatePerformanceAnalysisMutation =
      useGeneratePerformanceAnalysis();
    const closeSessionMutation = useCloseSession ();
    const {
      data: sessionPerformanceAnalysis,
      isLoading,
      isError,
      error,
      isSuccess,
      refetch
    } = useGetSessionPerformanceAnalysis(sessionId, token);

    useEffect(() => {
      if (isFinished) {
        generatePerformanceAnalysisMutation.mutate({sessionId, token}, {
          onSuccess: () => {
            closeSessionMutation.mutate({sessionId, token});
            refetch();
          },
          onError: (error) => {
            // If the error status is 409, it means the analysis already exists
            if (error.response.status === 409) {
              refetch();
            }
          },
        });
      }
    }, [isFinished, sessionId, token, refetch]);

    if (isLoading) {
      return <LoadingCard />;
    }

    if (isError) {
      return <Typography color="error">{error.message}</Typography>;
    }

    return (
      <ThemeProvider theme={theme}>
        <Grid container>
          {!isSuccess ? (
            <LoadingCard />
          ) : isError ? (
            // Render error message if there's an error
            <Typography color="error">
              {error.message}
            </Typography>
          ) : (
            <>
              <Grid item xs={12} md={4} lg={3}>
                {/* Card showing overall performance metrics */}
                <OverallPerformanceCard
                  data={sessionPerformanceAnalysis.data}
                  error={error}
                  isLoading={isLoading}
                />
                {/* Motivational Card is shown on mobile at this position */}
                {isMobile && (
                  <MotivationalCard
                    data={sessionPerformanceAnalysis.data}
                    error={error}
                    isLoading={isLoading}
                  />
                )}
                {/* Card displaying strengths and weaknesses */}
                <StrenghtsWeaknessCard
                  data={sessionPerformanceAnalysis.data}
                  error={error}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                {/* On larger screens, Motivational Card is shown here */}
                {!isMobile && (
                  <MotivationalCard
                    data={sessionPerformanceAnalysis.data}
                    error={error}
                    isLoading={isLoading}
                  />
                )}
                {/* Card showing conversation details */}
                <ConversationCard
                  data={sessionPerformanceAnalysis.data}
                  error={error}
                  isLoading={isLoading}
                />
              </Grid>
            </>
          )}
        </Grid>
      </ThemeProvider>
    );
  };

  const LoadingCard = () => (
    <Card sx={{ margin: 2, textAlign: "center", padding: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Preparing Performance Analysis...
        </Typography>
        <CircularProgress />
      </CardContent>
    </Card>
  );

  export default PerformanceAnalysisPage;
