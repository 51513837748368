// CustomCard.js
import { styled } from "@mui/system";
import { Button } from '@mui/material';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 500,
  padding: "0.5rem 1rem",
  borderRadius: "1rem",
  border: "none",
  cursor: "pointer",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "none",
  },
  transition: "all 0.3s ease",
}));

export default CustomButton;
