import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setAnalysis,
  setIsFetchingSessionPerformanceAnalysis,
} from "../../store/slices/performanceAnalysisSlice";
import { useTranslation } from "react-i18next";
import { Grid, Skeleton } from "@mui/material";
import "typeface-montserrat";
import "@fontsource/roboto/400.css";
import "@fontsource/poppins";
import TitledTextItem from "./TitledTextItem";
import myImage from "../../assets/images/success1.png";
import CustomCard from "./CustomCard";
import SkeletonLoader from "./SkeletonLoader";

const getTitle = (score) => {
  if (score <= 20) {
    return "It's all part of the process!";
  } else if (score <= 40) {
    return "You're gaining momentum!<br/>Keep it up!";
  } else if (score <= 60) {
    return "You're on track to success!";
  } else if (score <= 80) {
    return "You're almost there!<br/>Keep going!";
  } else {
    return "You're ready!<br/>Go get that job!";
  }
};

const MotivationalCard = ({ data, error, isLoading }) => {
  const analysis = data;
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <CustomCard>
        <Grid container direction="column">
          <Grid container direction="row">
            <Grid item xs={12} sm={5}>
              <img
                src={myImage}
                alt="Descriptive alt text"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TitledTextItem
                title={t(
                  getTitle(analysis?.performance_analysis?.global_score)
                )}
                titleSize={"h2"}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <TitledTextItem text={analysis?.performance_analysis?.summary} />
            )}
          </Grid>
        </Grid>
      </CustomCard>
    </Grid>
  );
};

export default MotivationalCard;
