import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HorizontalAppBar from "./components/navigation/HorizontalAppBar";
import Home from "./components/home/Home";
import PerformanceAnalysisPage from "./components/performanceAnalysis/PerformanceAnalysisPage";
import { Box, Container, createTheme, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styles/global.scss";
import "./i18n";
import UserProfilePage from "./components/profile/UserProfilePage";
import InterviewAppPage from "./components/interview/InterviewAppPage";

const createAppTheme = (mode) =>
  createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      h3: {
        fontSize: "2rem",
        "@media (min-width:600px)": {
          fontSize: "2.5rem",
        },
      },
    },
  });

const queryClient = new QueryClient();

const App = () => {
  const dispatch = useDispatch();
  const jobDescription = useSelector(
    (state) => state.submission.jobDescription
  );
  const timeLimit = useSelector((state) => state.submission.timeLimit);
  const extractedCVText = useSelector(
    (state) => state.submission.extractedCVText
  );
  const performanceAnalysis = useSelector(
    (state) => state.performanceAnalysis.performanceAnalysis
  );
  const conversation = useSelector((state) => state.interview.conversation);
  const selectedLanguage = useSelector(
    (state) => state.global.selectedLanguage
  );
  const { t, i18n } = useTranslation();
  const [appTheme] = useState(createAppTheme());

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <Router>
          <HorizontalAppBar />
          <Box
            sx={(theme) => ({
              paddingTop: "64px",
              minHeight: "100vh",
              background:
                theme.palette.mode === "light"
                  ? "#f0f0fb"
                  : "linear-gradient(45deg, #525252 60%, #616161 90%)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Container maxWidth="100%">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/session/performance_analysis"
                  element={
                    <PerformanceAnalysisPage
                      conversation={conversation}
                      analysis={performanceAnalysis}
                    />
                  }
                />
                <Route path="/account/*" element={<UserProfilePage />} />
                <Route path="/app/interviewer/*" element={<InterviewAppPage />} />
              </Routes>
            </Container>
          </Box>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
