// StartForm component

import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TextField,
  MenuItem,
  Stack,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CVUpload from "./CVUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  setJobDescription,
  setTimeLimit,
  setExtractedCVText,
} from "../../store/slices/submissionSlice";
import { setSessionId } from "../../store/slices/globalSlice";
import CustomCard from "../common/CustomCard";
import CustomButton from "../common/CustomButton";
import { useCreateSession } from "../../hooks/useInterviewProcess";

const StartForm = () => {
  console.log("Rendering StartForm");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const jobDescription = useSelector(
    (state) => state.submission.jobDescription
  );
  const timeLimit = useSelector((state) => state.submission.timeLimit);
  const language = useSelector((state) => state.global.selectedLanguage);
  const sessionId = useSelector((state) => state.global.sessionId);
  const extractedCVText = useSelector(
    (state) => state.submission.extractedCVText
  );
  const { t } = useTranslation();

  const createSessionMutation = useCreateSession();

  const handleSubmit = async (event) => {
    console.log("Submitting StartForm");
    console.log("sessionId:", sessionId);
    event.preventDefault();
    createSessionMutation.mutate(
      {
        jobDescription: jobDescription,
        timeLimit: timeLimit * 60,
        language: language,
        extractedCVText: extractedCVText,
        token: sessionStorage.getItem("accessToken"),
      },
      {
        onSuccess: (response) => {
          const session = response.data?.session_id;
          dispatch(setSessionId(session));
          navigate(`../session/in_progress`);
        },
        onError: (error) => {
          console.error("Error creating session:", error);
        },
      }
    );
  };

  const handleExtractedCVText = (text) => {
    dispatch(setExtractedCVText(text));
  };

  useEffect(() => {
    dispatch(setJobDescription(""));
    dispatch(setTimeLimit(1));
    dispatch(setExtractedCVText(""));
    dispatch(setSessionId(null));
  }, [dispatch]);

  return (
    <CustomCard
      component="form"
      onSubmit={handleSubmit}
      className="job-description-form"
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "1rem",
        padding: 3,
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      <Stack spacing={3}>
        <TextField
          label={t("jobDescription")}
          placeholder={t("jobDescriptionPlaceholder")}
          multiline
          rows={4}
          variant="outlined"
          value={jobDescription}
          onChange={(e) => dispatch(setJobDescription(e.target.value))}
          fullWidth
          required
          aria-label="Job Description"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            borderRadius: "1rem",
          }}
        />
        <CVUpload onExtractedCVText={(text) => handleExtractedCVText(text)} />
        <FormControl fullWidth variant="outlined">
          <InputLabel id="time-limit-label">{t("timeLimit")}</InputLabel>
          <Select
            labelId="time-limit-label"
            value={timeLimit}
            onChange={(e) => dispatch(setTimeLimit(e.target.value))}
            label="Time Limit"
            aria-label="Time Limit"
            sx={{
              borderRadius: "1rem",
            }}
          >
            <MenuItem value={1}>1 {t("minute")}</MenuItem>
            <MenuItem value={5}>5 {t("minutes")}</MenuItem>
            <MenuItem value={15}>15 {t("minutes")}</MenuItem>
            <MenuItem value={30}>30 {t("minutes")}</MenuItem>
            <MenuItem value={60}>1 {t("hour")}</MenuItem>
          </Select>
        </FormControl>
        <CustomButton type="submit" variant="contained">
          {t("Submit")}
        </CustomButton>
      </Stack>
    </CustomCard>
  );
};

export default StartForm;
