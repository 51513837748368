// InterviewAppPage component

import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Link } from "@mui/material";
import { Home } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// Import actions from slices
import { setConversation } from "../../store/slices/interviewSlice";
import {
  setShowPerformanceAnalysis,
  setPerformanceAnalysis,
} from "../../store/slices/performanceAnalysisSlice";

// Import components and styles
import Question from "./Question";
import StartForm from "./StartForm";
import CountdownTimer from "./CountdownTimer";
import "../../i18n";

const InterviewAppPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timeLimit = useSelector((state) => state.submission.timeLimit);
  const [questionActive, setQuestionActive] = useState(false);
  const { t } = useTranslation();

  const handleFinish = (conv) => {
    dispatch(setConversation(conv));
    dispatch(setShowPerformanceAnalysis(true));
  };

  const handleShowPerformanceAnalysis = (conv, analysis) => {
    dispatch(setConversation(conv));
    dispatch(setPerformanceAnalysis(analysis));
    dispatch(setShowPerformanceAnalysis(true));
  };

  const handleReturnToHub = () => {
    navigate("../account/hub");
  };

  return (
    <Box sx={{ display: "flex", padding: 0 }}>
      <Box sx={{ position: "fixed", top: 85, left: 10, zIndex: 1000 }}>
        <Button
          component={Link}
          variant="outlined"
          startIcon={<Home />}
          onClick={handleReturnToHub}
        >
          Back to Hub
        </Button>
      </Box>
      {questionActive && (
        <Box
          sx={{
            position: "fixed",
            top: 85,
            right: 10,
            zIndex: 1000,
            border: "1px solid",
            borderColor: "primary.main",
            borderRadius: "5px",
            padding: "10px",
          }}
        >
          <CountdownTimer timeLimit={timeLimit} startTimer={questionActive} />
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Routes>
          <Route path="/session/start" element={<StartForm />} />
          <Route
            path="/session/in_progress"
            element={
              <Question
                onMount={() => setQuestionActive(true)}
                onUnmount={() => setQuestionActive(false)}
                onFinish={handleFinish}
                onShowPerformanceAnalysis={handleShowPerformanceAnalysis}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default InterviewAppPage;
