// CustomCard.js
import { styled } from "@mui/system";
import Card from "@mui/material/Card";

const CustomCard = styled(Card)(({ theme, backgroundColor }) => ({
  borderRadius: "0.4rem",
  padding: theme.spacing(5),
  margin: theme.spacing(1),
  marginTop: theme.spacing(2),
  //boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.1)",
  boxShadow: "none",
  backgroundColor: backgroundColor || "#ffffff",
  border: "1px solid rgba(0, 0, 0, 0.15)",
}));

export default CustomCard;
