// Question component

import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setAutoRead,
  setAudioState,
  setConversation,
  setIsFinished,
} from "../../store/slices/interviewSlice";
import { setShowPerformanceAnalysis } from "../../store/slices/performanceAnalysisSlice";
import ResponseForm from "./ResponseForm";
import {
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Stack,
  Collapse,
} from "@mui/material";
import PerformanceAnalysisPage from "../performanceAnalysis/PerformanceAnalysisPage";
import { useTranslation } from "react-i18next";
import TitledTextItem from "../common/TitledTextItem";
import CustomCard from "../common/CustomCard";
import { useGenerateInterviewerResponse } from "../../hooks/useInterviewProcess";
import { useGenerateAudioFromText } from "../../hooks/useTextToSpeechProcess";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

function Question({ onMount, onUnmount }) {
  const dispatch = useDispatch();
  const jobDescription = useSelector(
    (state) => state.submission.jobDescription
  );
  const timeLimit = useSelector((state) => state.submission.timeLimit);
  const extractedCVText = useSelector(
    (state) => state.submission.extractedCVText
  );
  const selectedLanguage = useSelector(
    (state) => state.global.selectedLanguage
  );

  const autoRead = useSelector((state) => state.interview.autoRead);
  const conversation = useSelector((state) => state.interview.conversation);
  const isClosingStatement = useSelector(
    (state) => state.interview.isClosingStatement
  );
  const showPerformanceAnalysis = useSelector(
    (state) => state.performanceAnalysis.showPerformanceAnalysis
  );
  const sessionId = useSelector((state) => state.global.sessionId);
  const isFinished = useSelector((state) => state.interview.isFinished);
  const [audioUrl, setAudioUrl] = useState(null);
  const [lastQuestion, setLastQuestion] = useState(null);
  const { t } = useTranslation();

  const audioPlayerRef = useRef(null);

  const generateInterviewerResponseMutation = useGenerateInterviewerResponse();
  const generateAudioMutation = useGenerateAudioFromText();

  const isAudioLoading = generateAudioMutation.isLoading;
  const isAudioReady = generateAudioMutation.isSuccess && audioUrl;

  useEffect(() => {
    if (onMount) onMount();

    return () => {
      if (onUnmount) onUnmount();
    };
  }, [onMount, onUnmount]);

  useEffect(() => {
    generateInterviewerResponseMutation.mutate(
      {
        jobDescription: jobDescription,
        extractedCVText: extractedCVText,
        timeLimit: timeLimit,
        sessionId: sessionId,
        selectedLanguage: selectedLanguage,
        conversation: conversation,
        token: sessionStorage.getItem("accessToken"),
      },
      {
        onSuccess: (data) => {
          dispatch(
            setConversation({
              role: "assistant",
              content: data.data.question_text,
            })
          );
          setAudioUrl(null);
        },
        onError: (error) => {
          console.error("Error generating interviewer response:", error);
        },
      }
    );
  }, []);

  useEffect(() => {
    const newQuestion = !isClosingStatement
      ? generateInterviewerResponseMutation.data?.data?.question_text
      : generateInterviewerResponseMutation.data?.data?.closing_statement_text;
    if (
      autoRead &&
      generateInterviewerResponseMutation.isSuccess &&
      newQuestion &&
      newQuestion !== lastQuestion
    ) {
      generateAudioMutation.mutate(
        {
          text: newQuestion,
          session_id: sessionId,
          token: sessionStorage.getItem("accessToken"),
        },
        {
          onSuccess: (data) => {
            const newAudioUrl = data.data.file_url;
            if (newAudioUrl !== audioUrl) {
              setAudioUrl(newAudioUrl);
              setLastQuestion(newQuestion);
            }
          },
          onError: (error) =>
            console.error("Error in generating audio:", error),
        }
      );
    }
  }, [generateInterviewerResponseMutation.isSuccess]);

  useEffect(() => {
    if (audioUrl && autoRead && audioPlayerRef.current) {
      audioPlayerRef.current.audio.current.play();
    }
  }, [audioUrl]);

  const speakQuestion = (questionText) => {
    console.log("Preparing to speak question:", questionText);
    dispatch(setAudioState("playing"));
    if (!audioUrl) {
      generateAudioMutation.mutate(
        { text: questionText, session_id: sessionId, token: sessionStorage.getItem("accessToken")},
        {
          onSuccess: (data) => {
            setAudioUrl(data.data.file_url); // Update the audio URL here
          },
          onError: (error) => {
            console.error("Error in generating audio:", error);
            dispatch(setAudioState("idle"));
          },
        }
      );
    }
  };

  const handleFiniClick = () => {
    dispatch(setIsFinished(true));
    dispatch(setShowPerformanceAnalysis(true));
  };

  if (generateInterviewerResponseMutation.isError) {
    return (
      <Box color="red" textAlign="center">
        <Typography variant="h6" gutterBottom>
          Error: {generateInterviewerResponseMutation.error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <CustomCard
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "1rem",
          padding: 3,
          maxWidth: showPerformanceAnalysis ? "1600px" : "650px",
          height: "auto",
          margin: "auto",
          transition: "max-width 0.3s ease-in-out",
        }}
      >
        <Stack spacing={3}>
          {!isFinished && (
            <Box>
              <Collapse
                in={!generateInterviewerResponseMutation.isSuccess}
                timeout="auto"
                unmountOnExit
              >
                <CircularProgress
                  sx={{
                    borderRadius: "1rem",
                  }}
                />
              </Collapse>
              <Collapse
                in={generateInterviewerResponseMutation.isSuccess}
                timeout="auto"
                unmountOnExit
              >
                {generateInterviewerResponseMutation.isSuccess && (
                  <>
                    <TitledTextItem
                      text={
                        isClosingStatement
                          ? generateInterviewerResponseMutation.data.data
                              .closing_statement_text
                          : generateInterviewerResponseMutation.data.data
                              .question_text
                      }
                    />
                  </>
                )}
              </Collapse>
            </Box>
          )}
          {!isFinished && generateInterviewerResponseMutation.isSuccess && (
            <Box textAlign="left">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autoRead}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      dispatch(setAutoRead(isChecked));
                      if (isChecked) {
                        const newQuestion =
                          generateInterviewerResponseMutation.data.data
                            .question_text;
                        if (newQuestion !== lastQuestion || !audioUrl) {
                          speakQuestion(newQuestion);
                        }
                      }
                    }}
                    color="primary"
                    sx={{
                      borderRadius: "1rem",
                    }}
                  />
                }
                label={t("autoReadLabel")}
              />
              {/* Loader for Audio Generation */}
              {isAudioLoading && (
                <Box textAlign="center">
                  <CircularProgress />
                </Box>
              )}

              {/* Audio Player - Displayed when audio is ready */}
              {isAudioReady && !isFinished && autoRead && (
                <AudioPlayer
                  ref={audioPlayerRef}
                  autoPlay
                  src={audioUrl}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  layout="horizontal"
                />
              )}
            </Box>
          )}
          {!isFinished && generateInterviewerResponseMutation.isSuccess && (
            <ResponseForm
              interviewerResponseMutation={generateInterviewerResponseMutation}
              onFinish={handleFiniClick}
              isClosingStatement={isClosingStatement}
            />
          )}
        </Stack>
      </CustomCard>
      {isFinished && <PerformanceAnalysisPage />}
    </>
  );
}

export default Question;
