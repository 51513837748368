import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLanguage: 'en',
  isLoading: false,
  error: null,
  retryCount: 0,
  sessionId: null,
  response: '',
  interim: ''
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setRetryCount: (state, action) => {
      state.retryCount = action.payload;
    },
    setSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    }
  },
});

export const { setSelectedLanguage, setError, setIsLoading, setResponse, setRetryCount, setSessionId, setInterim } = globalSlice.actions;

export default globalSlice.reducer;
