// useUserInformationProcess.jsx

import { useQuery } from 'react-query';
import { fetchUserInfo } from '../services/userService';

export const useFetchUserInfo = (token) => {
  return useQuery(['userInfo'], () => fetchUserInfo(token), {
    cacheTime: 30 * 60 * 1000,
    enabled: !!token, 
  });
};
