import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Divider } from "@mui/material";
import "typeface-montserrat";
import "@fontsource/roboto/400.css";
import "@fontsource/poppins";
import CustomCard from "../common/CustomCard";
import SkeletonLoader from "../common/SkeletonLoader";

const StrenghtsWeaknessCard = ({ data, error, isLoading }) => {
  const analysis = data;
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <CustomCard elevation={4}>
        <Grid container spacing={1} direction="column">
          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="primary"
              fontWeight={600}
              sx={{ paddingBottom: 2 }}
            >
              {t("Strengths")}
            </Typography>
            {isLoading ? ( <SkeletonLoader /> ) : (
            <ul style={{ paddingLeft: "30px", marginTop: "0" }}>
              {analysis?.performance_analysis?.strengths
                .slice(2, -2)
                .split("', '")
                .map((strength, index) => (
                  <li key={index} style={{ paddingBottom: "20px" }}>
                    <Typography variant="body1" color="textSecondary">
                      {t(strength)}
                    </Typography>
                  </li>
                ))}
            </ul>
            )}
          </Grid>

          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          <Grid item xs={12}>
            <Typography
              variant="h5"
              color="primary"
              fontWeight={600}
              sx={{ paddingBottom: 2 }}
            >
              {t("Weaknesses")}
            </Typography>
            {isLoading ? ( <SkeletonLoader /> ) : (
            <ul style={{ paddingLeft: "30px", marginTop: "0" }}>
              {analysis?.performance_analysis?.areas_for_improvement
                .slice(2, -2)
                .split("', '")
                .map((area, index) => (
                  <li key={index} style={{ paddingBottom: "20px" }}>
                    <Typography variant="body1" color="textSecondary">
                      {t(area)}
                    </Typography>
                  </li>
                ))}
            </ul>
            )}
          </Grid>
        </Grid>
      </CustomCard>
    </Grid>
  );
};

export default StrenghtsWeaknessCard;
