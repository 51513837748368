// CVUpload component

import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFileName } from "../../store/slices/submissionSlice";
import { useDropzone } from "react-dropzone";
import mammoth from "mammoth";
import styles from "../../styles/_CVUpload.module.scss";
import * as pdfjs from 'pdfjs-dist';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;


const CVUpload = ({ onExtractedCVText }) => {
  const dispatch = useDispatch();
  const fileName = useSelector((state) => state.submission.fileName);
  const { t } = useTranslation();

  const extractPDFText = async (arrayBuffer) => {
    const loadingTask = pdfjs.getDocument({ data: arrayBuffer });
    const pdf = await loadingTask.promise;
    const maxPages = pdf.numPages;
    let text = "";

    for (let i = 1; i <= maxPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      const strings = content.items.map((item) => item.str);
      text += strings.join(" ");
    }

    return text;
  };

  const extractDocText = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer });
    const text = result.value;
    return text;
  };

  const handleFileChange = useCallback(async (file) => {
    if (file) {
      let text = "";
      if (file.type === "application/pdf") {
        // Extract text from PDF
        const arrayBuffer = await file.arrayBuffer();
        text = await extractPDFText(arrayBuffer);
      } else if (file.name.endsWith(".docx")) {
        // Extract text from DOCX
        text = await extractDocText(file);
      }

      // Pass the extracted text to the parent component
      onExtractedCVText(text);
    }
  }, [onExtractedCVText]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const validFile = acceptedFiles.find(file =>
        file.type === "application/pdf" ||
        file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
  
      if (validFile) {
        // Update the fileName state when a valid file is dropped
        dispatch(setFileName(validFile.name));
        handleFileChange(validFile);
      } else {
        console.warn("Invalid file type. Only PDF and DOCX files are accepted.");
      }
    },
    [handleFileChange, dispatch]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()} className={`${styles.dropzoneContainer}`}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>{t('cvDropzoneHover')}</p>
      ) : fileName ? (
        <p>File: {fileName}</p>
      ) : (
        <p>{t('cvDropZoneDescription')}</p>
      )}
    </div>
  );
};

export default CVUpload;
