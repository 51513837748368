import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conversation: [],
  isDictating: false,
  currentQuestion: null,
  closingStatement: null,
  autoRead: false,
  audioState: 'idle',
  isClosingStatement: false,
  isFinished: false,
  interim: '',
};

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    setConversation: (state, action) => {
      state.conversation = [...state.conversation, action.payload];
    },
    setIsDictating: (state, action) => {
      state.isDictating = action.payload;
    },
    setInterim: (state, action) => {
      state.interim = action.payload;
    },
    setCurrentQuestion: (state, action) => {
      state.currentQuestion = action.payload;
    },
    setClosingStatement: (state, action) => {
      state.closingStatement = action.payload;
    },
    setAutoRead: (state, action) => {
      state.autoRead = action.payload;
    },
    setAudioState: (state, action) => {
      state.audioState = action.payload;
    },
    setIsClosingStatement: (state, action) => {
      state.isClosingStatement = action.payload;
    },
    setIsFinished: (state, action) => {
      state.isFinished = action.payload;
    }
  },
});

export const { setConversation, setIsDictating, setAutoRead, setAudioState, setCurrentQuestion, setIsClosingStatement, setIsFinished, setInterim, setClosingStatement } = interviewSlice.actions;

export default interviewSlice.reducer;
