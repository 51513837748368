// userService.jsx

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const handleResponse = async (response) => {
    if (
      response.status === 201 ||
      response.status === 200 ||
      response.status === 202
    ) {
      const data = await response.json();
      return { status: response.status, data };
    } else if (response.status === 204) {
      return { status: 204 };
    } else {
      throw new Error(
        `HTTP error! status: ${response.status} ${response.statusText} ${response.url}`
      );
    }
  };

export const fetchUserInfo = async (token) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/users/me`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`
        },
      });
      return handleResponse(response);
    } catch (err) {
      console.error(err.message);
      return { status: 500, error: err.message };
    }
  };
  