// usePerformanceAnalysisProcess.jsx
// Custom hooks for performance analysis process

import { useQuery, useMutation } from "react-query";
import {
  generatePerformanceAnalysis,
  getSessionPerformanceAnalysis,
  getUserSessionSummaries,
} from "../services/performanceAnalysisService";

// Common retry function for React Query
const shouldRetry = (failureCount, error) => {
  const status = error?.response?.status;
  return status === 500 || status === 502;
};

const useGeneratePerformanceAnalysis = () => {
  return useMutation(generatePerformanceAnalysis);
};

const useGetSessionPerformanceAnalysis = (sessionId, token) => {
  return useQuery(
    ["sessionPerformanceAnalysis", sessionId, token],
    () => getSessionPerformanceAnalysis(sessionId, token),
    {
      retry: (failureCount, error) => {
        return (
          shouldRetry(failureCount, error) ||
          error.message === "Performance analysis not yet available"
        );
      },
    }
  );
};

const useGetUserSessionSummaries = (userId, amount, token) => {
  return useQuery(
    ["userSessionSummaries", userId, amount, token],
    () => getUserSessionSummaries(userId, amount, token),
    { 
      retry: shouldRetry,
      enabled: !!userId 
    }
  );
};

export {
  useGeneratePerformanceAnalysis,
  useGetSessionPerformanceAnalysis,
  useGetUserSessionSummaries,
};
