// performanceAnalysisSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  performanceAnalysis: null,
  showPerformanceAnalysis: false,
  analysis: null,
  isAccordionExpanded: false,
  userSessionSummaries: []
};

const performanceAnalysisSlice = createSlice({
  name: 'performanceAnalysis',
  initialState,
  reducers: {
    setPerformanceAnalysis: (state, action) => {
      state.performanceAnalysis = action.payload;
    },
    setShowPerformanceAnalysis: (state, action) => {
      state.showPerformanceAnalysis = action.payload;
    },
    setAnalysis: (state, action) => {
      state.analysis = action.payload;
    },
    setIsAccordionExpanded: (state, action) => {
      state.isAccordionExpanded = action.payload;
    },
    setUserSessionSummaries: (state, action) => {
      state.userSessionSummaries = action.payload;
    }
  }
});

export const { setPerformanceAnalysis, setShowPerformanceAnalysis, setAnalysis, setIsAccordionExpanded, setUserSessionSummaries } = performanceAnalysisSlice.actions;

export default performanceAnalysisSlice.reducer;
