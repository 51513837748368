// use text to speech process custom hook

import { useMutation } from "react-query";
import { generateAudioFromText } from "../services/textToSpeechService";

const useGenerateAudioFromText = () => {
  return useMutation(generateAudioFromText);
};

export { useGenerateAudioFromText };
